exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-collection-js": () => import("./../../../src/pages/about/collection.js" /* webpackChunkName: "component---src-pages-about-collection-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-returns-js": () => import("./../../../src/pages/about/returns.js" /* webpackChunkName: "component---src-pages-about-returns-js" */),
  "component---src-pages-about-shipping-js": () => import("./../../../src/pages/about/shipping.js" /* webpackChunkName: "component---src-pages-about-shipping-js" */),
  "component---src-pages-about-tech-js": () => import("./../../../src/pages/about/tech.js" /* webpackChunkName: "component---src-pages-about-tech-js" */),
  "component---src-pages-art-20th-century-art-js": () => import("./../../../src/pages/art/20th-century-art.js" /* webpackChunkName: "component---src-pages-art-20th-century-art-js" */),
  "component---src-pages-art-caribbean-art-js": () => import("./../../../src/pages/art/caribbean-art.js" /* webpackChunkName: "component---src-pages-art-caribbean-art-js" */),
  "component---src-pages-art-haitian-art-js": () => import("./../../../src/pages/art/haitian-art.js" /* webpackChunkName: "component---src-pages-art-haitian-art-js" */),
  "component---src-pages-art-sports-art-js": () => import("./../../../src/pages/art/sports-art.js" /* webpackChunkName: "component---src-pages-art-sports-art-js" */),
  "component---src-pages-art-vietnamese-art-js": () => import("./../../../src/pages/art/vietnamese-art.js" /* webpackChunkName: "component---src-pages-art-vietnamese-art-js" */),
  "component---src-pages-books-art-books-js": () => import("./../../../src/pages/books/art-books.js" /* webpackChunkName: "component---src-pages-books-art-books-js" */),
  "component---src-pages-books-auction-catalogs-js": () => import("./../../../src/pages/books/auction-catalogs.js" /* webpackChunkName: "component---src-pages-books-auction-catalogs-js" */),
  "component---src-pages-books-modern-lit-js": () => import("./../../../src/pages/books/modern-lit.js" /* webpackChunkName: "component---src-pages-books-modern-lit-js" */),
  "component---src-pages-books-nordic-noir-js": () => import("./../../../src/pages/books/nordic-noir.js" /* webpackChunkName: "component---src-pages-books-nordic-noir-js" */),
  "component---src-pages-books-west-indian-lit-js": () => import("./../../../src/pages/books/west-indian-lit.js" /* webpackChunkName: "component---src-pages-books-west-indian-lit-js" */),
  "component---src-pages-cart-changed-js": () => import("./../../../src/pages/cart-changed.js" /* webpackChunkName: "component---src-pages-cart-changed-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../../../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sent-js": () => import("./../../../src/pages/sent.js" /* webpackChunkName: "component---src-pages-sent-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-painting-js": () => import("./../../../src/templates/painting.js" /* webpackChunkName: "component---src-templates-painting-js" */)
}

